<template>
  <div
    id="header-project"
    ref="menu"
    :class="{ 'organism-menu-bar-project': true, scrolled: isScrolled }"
  >
    <div class="row align-items-center">
      <div
        class="col"
        :class="{
          'col-xs-4 col-3': showProjectSelect,
          'col-xs-8 col-3': showBackButton,
        }"
      >
        <div class="block-element">
          <div v-if="showBackButton" class="inline-elements">
            <router-link
              tag="a"
              :to="{
                name: 'SpecificProject',
                params: { projectId: activeProject },
              }"
              class="back-link"
            >
              <AtomIcon icon="angle-left" :size="32" color="#000" />
            </router-link>
          </div>
          <router-link tag="div" :to="{ name: 'Home' }" class="inline-elements">
            <AtomLogo />
          </router-link>
          <div v-if="showProjectSelect" class="inline-elements hidden-xs">
            <AtomDropdown
              v-if="projectOptions.length > 1"
              :options="projectOptions"
              :selected="currentProject"
              title-label="Change Project"
              @change="handleProjectChange"
            />
          </div>
        </div>
      </div>
      <div
        class="col"
        :class="{
          'col-xs-8 col-9': showProjectSelect,
          'col-xs-4 col-9': showBackButton,
        }"
      >
        <div class="block-element text-right">
          <div
            class="inline-elements hidden-lg"
            v-if="!availableSection && showProjectSelect"
          >
            <AtomButtonLink
              href="javascript:void(0);"
              label="Check Availability"
              @click="handleScrollToTop"
            />
          </div>
          <div class="inline-elements">
            <div class="menu">
              <slot></slot>
            </div>
          </div>
          <div class="inline-elements">
            <MoleculeMenuTrigger />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import MoleculeMenuTrigger from "../molecules/MoleculeMenuTrigger";
import AtomIcon from "../atoms/common/AtomIcon";
import AtomButtonLink from "../atoms/common/AtomButtonLink";
import AtomDropdown from "../atoms/common/AtomDropdown";
import { isMobile, isTablet } from "../../helpers/mobile/DeviceType";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../helpers/util";
export default {
  name: "OrganismMenuBarProject",
  components: {
    AtomDropdown,
    AtomButtonLink,
    AtomIcon,
    MoleculeMenuTrigger,
    AtomLogo,
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.initScrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.initScrollListener);
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return;
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_ACTIVE_PROJECT
        ),
        option.value
      );
      this.$router.push({
        name: "SpecificProject",
        params: { projectId: option.value },
      });
    },
    handleScrollToTop() {
      this.scrollTo3DSection();
      if (isMobile() || isTablet()) {
        const base = this.$store.getters.constants.base;
        this.$store.dispatch(
          base.withNamespace(base.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
        );
        this.$store.dispatch(
          base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
          false
        );
        const requestOffer = this.$store.getters.constants.requestOffer;
        this.$store.dispatch(
          requestOffer.withNamespace(
            requestOffer.action.CHANGE_CART_SPACES_STATE
          ),
          false
        );
      }
    },
    scrollTo3DSection() {
      const sections = this.sections;
      const buildingSection = this.sections.find((s) => s.type === "Building");
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`);
      }
    },
  },
  computed: {
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject;
      });
      if (!currentProject) {
        return "";
      }
      return currentProject.slug;
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects];
      return projects.map((project) => {
        return { label: project.name, value: project.slug };
      });
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    showBackButton() {
      const { name } = this.$route;
      return name === "SpecificSpace";
    },
    showProjectSelect() {
      const { name } = this.$route;
      return name !== "SpecificSpace";
    },
    sections() {
      const { page } = this.$route.meta;
      return page ? page.sections : [];
    },
    generalConfig() {
      return this.$store.state?.base?.meta?.general;
    },
    availableSection() {
      return this.generalConfig?.availableSection || true;
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar-project {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.625rem;
  z-index: 9999;
  background: $white;
  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
  .inline-elements {
    display: inline-block;
    vertical-align: middle;
  }
  .back-link {
    width: 3.25rem;
    height: 3.25rem;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      width: 52px;
      height: 52px;
    }
    .icon {
      font-size: 1.875rem !important;
      color: $black !important;
      @media only screen and (max-width: 767px) {
        font-size: 22px !important;
      }
    }
  }
  .atom-logo {
    margin: 0 1.563rem 0 0.625rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0 8px;
    }
    img {
      display: block;
      max-height: 4rem;
      @media only screen and (max-width: 767px) {
        max-height: 35px;
      }
    }
  }
  .link-atom {
    background: rgba(58, 189, 89, 0.2);
    border-color: transparent;
    padding: 0.875rem 1.25rem;
    margin-right: 0.625rem;
    @media only screen and (max-width: 767px) {
      padding: 15px 10px;
      margin-right: 8px;
    }
    span {
      color: #3abd59;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &:hover {
      background: #3abd59;
      border-color: transparent;
      @media only screen and (max-width: 1200px) {
        background: rgba(58, 189, 89, 0.2);
      }
      span {
        color: $white;
        @media only screen and (max-width: 1200px) {
          color: #3abd59;
        }
      }
    }
    &.active {
      background: #3abd59 !important;
      border-color: transparent !important;
      span {
        color: $white !important;
      }
    }
  }
  .molecule-menu-trigger {
    background: transparent;
    &:hover,
    &.active {
      background: transparent !important;
      .icon {
        color: $black !important;
      }
    }
  }
  .menu {
    .sidebar-link {
      cursor: pointer;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #656565;
      text-decoration: none;
      margin: 0 1rem;
      padding-bottom: 0.25rem;
      border-bottom: 0.25rem solid transparent;
      transition: all 0.2s ease-in-out 0s;
      &:hover,
      &.active {
        color: $second-color;
        border-color: $second-color;
      }
    }
  }
}
</style>
