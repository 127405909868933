var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismSidebar", [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _vm.buildingStaticPages.length > 1
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "subtitle" }, [_vm._v("Cities")]),
                    _vm._l(_vm.buildingStaticPages, function (page, i) {
                      return _c("div", { key: i }, [
                        _c(
                          "span",
                          {
                            staticClass: "sidebar-link",
                            on: {
                              click: function ($event) {
                                return _vm.handleProjectChange(page)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(page.menuLabel || page.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.projects.length > 1
              ? _c("div", { staticClass: "separator" })
              : _vm._e(),
            _vm.customLinks.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.customLinks, function (customLink, i) {
                    return _c(
                      "a",
                      {
                        key: i,
                        staticClass: "sidebar-link",
                        attrs: { href: "" + customLink.slug, target: "_blank" },
                      },
                      [_vm._v(_vm._s(customLink.name))]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.containsSpaceSlug
              ? _c(
                  "div",
                  _vm._l(_vm.sections, function (section, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        _vm.allowSection(section)
                          ? _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  "active-class": "active",
                                  tag: "a",
                                  to: {
                                    name: "SpecificSpace",
                                    params: {
                                      projectId: _vm.activeProject,
                                      spaceId: _vm.activeSpace,
                                    },
                                    hash: "#" + section.hashAnchor,
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.hashChecker(
                                      "#" + section.hashAnchor
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(section.menuLabel) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  _vm._l(_vm.sections, function (section, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        _vm.allowSection(section)
                          ? _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  "active-class": "active",
                                  tag: "a",
                                  to: {
                                    name: _vm.pageName,
                                    params: { projectId: _vm.activeProject },
                                    hash: "#" + section.hashAnchor,
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.hashChecker(
                                      "#" + section.hashAnchor
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(section.menuLabel) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
            _vm._l(_vm.projectSpecificStaticPages, function (page, i) {
              return _c(
                "div",
                { key: i },
                [
                  !_vm.externalLink(page.path)
                    ? _c(
                        "router-link",
                        {
                          staticClass: "sidebar-link",
                          attrs: {
                            "active-class": "active",
                            tag: "a",
                            to: {
                              name: page.name,
                              params: { projectId: _vm.activeProject },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(page.menuLabel || page.name) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "sidebar-link",
                          attrs: { href: page.path },
                        },
                        [_vm._v(_vm._s(page.menuLabel || page.name))]
                      ),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "social-links" }, [
          _vm.instagramUrl
            ? _c("a", { attrs: { href: _vm.instagramUrl } }, [
                _vm._v("Instagram"),
              ])
            : _vm._e(),
          _vm.linkedinUrl
            ? _c("a", { attrs: { href: _vm.linkedinUrl } }, [
                _vm._v("Linkedin"),
              ])
            : _vm._e(),
          _vm.facebookUrl
            ? _c("a", { attrs: { href: _vm.facebookUrl } }, [
                _vm._v("Facebook"),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("OrganismMenuBarProject", [
        _vm.containsSpaceSlug
          ? _c(
              "div",
              { staticClass: "hidden-xs" },
              _vm._l(_vm.sections, function (section, i) {
                return _vm.allowSection(section)
                  ? _c(
                      "router-link",
                      {
                        key: i,
                        staticClass: "sidebar-link navigation-link",
                        attrs: {
                          "active-class": "active",
                          tag: "a",
                          to: {
                            name: "SpecificSpace",
                            params: {
                              projectId: _vm.activeProject,
                              spaceId: _vm.activeSpace,
                            },
                            hash: "#" + section.hashAnchor,
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hashChecker("#" + section.hashAnchor)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(section.menuLabel) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e()
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "hidden-xs" },
              _vm._l(_vm.sections, function (section, i) {
                return _vm.allowSection(section)
                  ? _c(
                      "router-link",
                      {
                        key: i,
                        staticClass: "sidebar-link navigation-link",
                        attrs: {
                          "active-class": "active",
                          tag: "a",
                          to: {
                            name: _vm.pageName,
                            params: { projectId: _vm.activeProject },
                            hash: "#" + section.hashAnchor,
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hashChecker("#" + section.hashAnchor)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(section.menuLabel) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e()
              }),
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }