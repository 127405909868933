var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "menu",
      class: { "organism-menu-bar-project": true, scrolled: _vm.isScrolled },
      attrs: { id: "header-project" },
    },
    [
      _c("div", { staticClass: "row align-items-center" }, [
        _c(
          "div",
          {
            staticClass: "col",
            class: {
              "col-xs-4 col-3": _vm.showProjectSelect,
              "col-xs-8 col-3": _vm.showBackButton,
            },
          },
          [
            _c(
              "div",
              { staticClass: "block-element" },
              [
                _vm.showBackButton
                  ? _c(
                      "div",
                      { staticClass: "inline-elements" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "back-link",
                            attrs: {
                              tag: "a",
                              to: {
                                name: "SpecificProject",
                                params: { projectId: _vm.activeProject },
                              },
                            },
                          },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "angle-left",
                                size: 32,
                                color: "#000",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    staticClass: "inline-elements",
                    attrs: { tag: "div", to: { name: "Home" } },
                  },
                  [_c("AtomLogo")],
                  1
                ),
                _vm.showProjectSelect
                  ? _c(
                      "div",
                      { staticClass: "inline-elements hidden-xs" },
                      [
                        _vm.projectOptions.length > 1
                          ? _c("AtomDropdown", {
                              attrs: {
                                options: _vm.projectOptions,
                                selected: _vm.currentProject,
                                "title-label": "Change Project",
                              },
                              on: { change: _vm.handleProjectChange },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col",
            class: {
              "col-xs-8 col-9": _vm.showProjectSelect,
              "col-xs-4 col-9": _vm.showBackButton,
            },
          },
          [
            _c("div", { staticClass: "block-element text-right" }, [
              !_vm.availableSection && _vm.showProjectSelect
                ? _c(
                    "div",
                    { staticClass: "inline-elements hidden-lg" },
                    [
                      _c("AtomButtonLink", {
                        attrs: {
                          href: "javascript:void(0);",
                          label: "Check Availability",
                        },
                        on: { click: _vm.handleScrollToTop },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "inline-elements" }, [
                _c("div", { staticClass: "menu" }, [_vm._t("default")], 2),
              ]),
              _c(
                "div",
                { staticClass: "inline-elements" },
                [_c("MoleculeMenuTrigger")],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }